// import * as APICaller from '../APIs/APICaller';
import { API, HTTPMethod } from "./API";
import { URLs } from "./URLs";

export function APIControllerError(code, message) {
	this.code = code
	this.message = message
}

export function APIControllerResponse(object, error) {
	this.object = object
	this.error = error
}


export function VerifyOTP(OTP) {
	let params = JSON.stringify({ 'OTP': OTP })
	console.log('params:', params)
	let url = URLs.base + URLs.VerifyOTP;

	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function GetESIMCountries(CountryName, Region) {
	let params = JSON.stringify({
		CountryName: CountryName,
		Region: Region
	})
	let url = URLs.base + URLs.GetESIMCountries;
	return new Promise((resolve, reject) => {
		API.shared.requestGet(HTTPMethod.get, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function GetESIMBundles(CountryName, Region) {
	let params = JSON.stringify({
		"CountryName": CountryName,
		"Region": Region,
	})
	console.log('params:', params)
	let url = URLs.base + URLs.GetESIMBundles;
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}



export function GetESIMOrders(PageNumber, PageSize, SortExpression, SortDirection) {
	let params = JSON.stringify({
		"PageNumber": PageNumber,
		"PageSize": PageSize,
		"SortExpression": "OrderedDateTime",
		"SortDirection": "DESC",

	})
	console.log('params:', params)
	let url = URLs.base + URLs.GetESIMOrders;
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}



export function RegisterESIMLife(CustomerName, EmailAddress, MobileNumber, AffiliateCode, Password, ConfirmPassword) {
	let params = JSON.stringify({
		'CustomerName': CustomerName,
		'EmailAddress': EmailAddress,
		'MobileNumber': MobileNumber,
		'AffiliateCode': AffiliateCode,
		"Password": Password,
		"ConfirmPassword": ConfirmPassword
	})
	let url = URLs.base + URLs.RegisterESIMLife
	return new Promise((resolve, reject) => {
		API.shared.requestSiginUp(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}


export function GetNav() {
	let params = JSON.stringify({

	})
	console.log('params:', params)
	let url = URLs.base + URLs.GetNav;
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function ContactUs(FirstName, LastName, Email, Message) {
	let params = JSON.stringify({
		"FirstName": FirstName,
		"LastName": LastName,
		"Email": Email,
		"Message": Message
	})
	console.log('params:', params)
	let url = URLs.base + URLs.ContactUs;
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}

export function PaymentInitiate(FundAmount, RequestGuid, PaymentType) {
	let params = JSON.stringify({
		"PaymentType": PaymentType,
		"FundAmount": FundAmount,
		"RequestGuid": RequestGuid,
	})
	console.log('params:', params)
	let url = URLs.base + URLs.PaymentInitiate;
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function OrderESIM(BundleName, CountryISO, SuperPaymentTransactionId, StripeTransactionId, RequestGuid,ICCID) {
	let params = JSON.stringify({
		"BundleName": BundleName,
		"CountryISO": CountryISO,
		"SuperPaymentTransactionId": SuperPaymentTransactionId,
		"StripeTransactionId": StripeTransactionId,
		"RequestGuid": RequestGuid,
		"ICCID": ICCID,
	})
	console.log('params:', params)
	let url = URLs.base + URLs.OrderESIM;
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function ResendVerificationEmail(EmailAddress) {
	let params = JSON.stringify({
		"EmailAddress": EmailAddress,
	})
	console.log('params:', params)
	let url = URLs.base + URLs.ResendOTP;
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function VerifyEmail(EmailAddress, OTPDetails) {
	let params = JSON.stringify({
		"EmailAddress": EmailAddress,
		"OTPDetails": OTPDetails,
	})
	console.log('params:', params)
	let url = URLs.base + URLs.VerifyOTP;
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
export function ViewESIMOrderDetails(ICCID) {
	let params = JSON.stringify({
		"ICCID": ICCID,
	})
	console.log('params:', params)
	let url = URLs.base + URLs.ViewESIMOrderDetails;
	return new Promise((resolve, reject) => {
		API.shared.request(HTTPMethod.post, url, params).then((response) => {
			if (response && response.StatusCode === 401) {
				let error = new APIControllerError(response.StatusCode, response.Message)
				resolve(new APIControllerResponse(null, error))
			}
			else if (response) {
				resolve(new APIControllerResponse(response, null))
			}
		}).catch((error) => {
			resolve(new APIControllerResponse(null, error))
		})
	})
}
