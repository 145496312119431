import { IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosArrowRoundBack } from "react-icons/io";
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Nodata, PaymentView, Text } from '../../components';
import { Images, useTheme } from '../../config';
import { bytesToSize, formatCurrency, IsEmpty } from '../../config/appConstant';
import { setBundle } from '../../redux/store';
import './SelectedBundle.css';

const SelectedBundle = (props) => {
    const { selectBundle, handeBundleSelect } = props
    const S_T = useSelector((state) => state.S_T);
    const dispatch = useDispatch();
    const BaseColor = useTheme().theme.colors
    const { t } = useTranslation();
    const [showPaymentView, setshowPaymentView] = useState(false);


    useEffect(() => {
        setshowPaymentView(false)
    }, [selectBundle])

    const handleContinue = () => {
            let Object = {
                ...S_T,
            }
            dispatch(setBundle(Object));
            setshowPaymentView(true)
    };

    return (
        <>
            {IsEmpty(selectBundle) ?
                <div style={{ marginTop: '28px' }}>
                    <Nodata Message={'Not selected any Bundle'} />
                </div>
                :
                <div>
                    <div className='align-success-text'>
                        {showPaymentView && <IconButton onClick={() => setshowPaymentView(false)}><IoIosArrowRoundBack onClick={() => setshowPaymentView(false)} size={30} color={BaseColor.text} /></IconButton>}
                        <Text DarkGrayTextColor>{t(showPaymentView ? "Choose_Payment_Method" : "You_have_Selected")}</Text>
                    </div>
                    {!showPaymentView ?
                        <>
                            <Card elevation={5} style={{ borderRadius: '15px', border: '5px solid #0000', margin: 5 }} CardColor={BaseColor.card} >
                                <div className="selectedbundle-card">
                                    <div className='selected-flex'>
                                        <div><img src={Images.Group_ic} width={20} alt="Icon" /></div>
                                        <div>
                                            <div className='data-plan'><Text whiteColor>{(selectBundle.DataAmount == -1 ? 'Unlimited' : bytesToSize(selectBundle.DataAmount * 1000000)) + ' / ' + (selectBundle.Duration > 1 ? (selectBundle.Duration + ' Days') : (selectBundle.Duration + ' Day'))}</Text></div>
                                        </div>
                                    </div>
                                    <div className='align-selectedbundle-text'>
                                        <Text DarkGrayTextColor>United States</Text>
                                    </div>
                                    <div className='price-selectedbundle-flex'>
                                        <Text bold mainColor>{selectBundle.BundleName}</Text>
                                        <Text DarkGrayTextColor>{formatCurrency(selectBundle.PriceTotal)}</Text>
                                    </div>
                                    <div className='align-selectedbundle-text'>
                                        <Text subhead DarkGrayTextColor>{selectBundle.BundleDescription}</Text>
                                    </div>

                                </div>


                                <div className='selectedbundle-select'>
                                    <Button Medium type={5} title='Back' onClick={() => handeBundleSelect('')} />
                                    <Button full type={2} title='Continue' onClick={handleContinue} />
                                </div>
                            </Card>
                        </>
                        :
                        <PaymentView selectBundle={selectBundle} backButttonPress={() => setshowPaymentView(false)} />}
                </div>
            }
        </>

    );
};

export default SelectedBundle;
