import React from 'react';
import './Destinationfaq.css';
import { Accordion, Button, Text } from '../../components';
import { useTranslation } from 'react-i18next';

const Destinationfaq = () => {
    const { t } = useTranslation();
    return (
        <section className="destinationfaq-main">
            <div className="header">
                <Text bold mainColor heading >{t("Destinationfaq_title")}</Text>
            </div>
            <div className='faq-body'>
                <Accordion title="Lorem ipsum dolor sit amet consectetur" useTextComponent={true}>
                    <Text >This is the content for Apple.</Text>
                </Accordion>
                <Accordion title="Lorem ipsum dolor sit amet consectetur" useTextComponent={true}>
                    <Text>This is the content for Banana.</Text>
                </Accordion>
                <Accordion title="Lorem ipsum dolor sit amet consectetur" useTextComponent={true}>
                    <Text>This is the content for Cherry.</Text>
                </Accordion>
                <div className='faq-button'>
                    <Button round capsule variant='outlined' title='Lorem ipsum' /></div>
            </div>

        </section>
    );
}

export default Destinationfaq
