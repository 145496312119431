import React, { useState } from 'react';
import './navbar.css';
import { CommonLoader, SingaleCheckBox, Text } from '../../components';
import { useTranslation } from 'react-i18next';
import { languages, onLogout, ThemeSupport } from '../../config/appConstant';
import { useDispatch, useSelector } from 'react-redux';
import { setDark, setLanguage, setTheme } from '../../redux/store';
import { FaLanguage } from "react-icons/fa6";
import { BaseColor } from '../../config';
import CommonSwitch from '../Switch/CommonSwitch';
import { MdOutlineDownloadDone } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";


function LanguageDropdown() {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [Loader, setLoader] = useState(false);
  const force_dark = useSelector((state) => state.force_dark);
  const theme = useSelector((state) => state.theme);

  const dispatch = useDispatch();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(setLanguage(lng));
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);

  };

  const ChangeTheme = (name) => {
    setLoader(true)
    dispatch(setTheme(name));
    setTimeout(() => {
      window.location.reload()
    }, 1000);

  }
  const ForceDark = (name) => {
    setLoader(true)
    dispatch(setDark(name));
    setTimeout(() => {
      window.location.reload()
    }, 1000);

  }


  return (
    <div className="dropdown">
      {Loader && <CommonLoader />}
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <FaLanguage style={{ color: '#fff', fontSize: 30 }} />
        {/* <Text body1 bold whiteColor>{t("Select_Language")}</Text> */}
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          {languages.map((language) => (
            <div onClick={() => changeLanguage(language.code)} key={language.code} className="dropdown-item">
              <Text >{language.name}</Text>
            </div>
          ))}
          <div style={{ paddingLeft: 5, borderTop: '1px solid', borderTopColor: BaseColor.mainColor }}>
            <Text bold primaryColor>theme</Text>
          </div>
          {ThemeSupport.map((language) => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => ChangeTheme(language.name)} key={language.code} className="dropdown-item">
              <Text capitalize>{language.name}</Text>
              {language.name == theme && <MdOutlineDownloadDone fontSize={25} />}
            </div>
          ))}
          <div style={{
            paddingLeft: 5, display: 'flex',
            flexDirection: 'column', borderTop: '1px solid', borderTopColor: BaseColor.mainColor
          }}>
            <Text bold primaryColor>Dark</Text>
            <CommonSwitch checked={force_dark} onChange={(text) => ForceDark(text.target.checked)} />
          </div>

          <div style={{
            paddingLeft: 5, display: 'flex',
            flexDirection: 'column', borderTop: '1px solid', borderTopColor: BaseColor.mainColor
          }}> </div>
          <div onClick={() => onLogout()} className="dropdown-item" style={{ display: 'flex', alignItems: 'center' }}>
            <AiOutlineLogout style={{ marginRight: 10, fontSize: 20 }} />
            <Text accentColor>{t("Logout")}</Text>
          </div>



        </div>
      )
      }
    </div >
  );
}

export default LanguageDropdown;
