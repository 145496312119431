

const IsProduction = true

export const GetBaseURL = () => {
    var HostName = window.location.hostname
    var ReturnURL = '';
    if (HostName.indexOf("elitebdm.thestaging.cc") >= 0) {
        ReturnURL = 'https://api.esim-life.com'
    }
    else {  //Local
        // ReturnURL = 'https://bl.api.mybauschreach.com'
        ReturnURL = 'https://api.esim-life.com'

    }
    return ReturnURL
}


export function GetSuperPaymentURL() {
    if (IsProduction) {  //Development
        return 'https://api.test.superpayments.com/v2/payments' // testing url
    } else {    //Live
        return 'https://api.superpayments.com/v2/payments' // Live url
    }
}

export function GetSuperPaymentToken() {
    if (IsProduction) {  //Development
        return `PSK_RKM38qEYTbVn7qMEqNJVSFekfBQpyoHQoQeDv--K`// Testing Key
    } else {  //Live
        return `PSK_l3qoG_cPB_mbyzXfAkWearT_BiFYLXA4R9A_YLkh` // Live Key
    }
}
export const SuperPaymentRedirectURL = {
    'Sucess': (IsProduction) ? 'https://elitebdm.thestaging.cc/PaymentSuccess' : 'https://elitebdm.thestaging.cc/PaymentSuccess',
    'Cancel': (IsProduction) ? 'https://elitebdm.thestaging.cc/PaymentFailure' : 'https://simply.elitemobile.com/PaymentFailure',
    'Failure': (IsProduction) ? 'https://elitebdm.thestaging.cc/PaymentSuccess' : 'https://simply.elitemobile.com/PaymentFailure',
}
export const StripePaymentRedirectURL = {
    'Sucess': (IsProduction) ? 'https://elitebdm.thestaging.cc/PaymentSuccess' : 'https://elitebdm.thestaging.cc/PaymentSuccess',
    'Cancel': (IsProduction) ? 'https://elitebdm.thestaging.cc/PaymentFailure' : 'https://simply.elitemobile.com/PaymentFailure',
    'Failure': (IsProduction) ? 'https://elitebdm.thestaging.cc/PaymentFailure' : 'https://simply.elitemobile.com/PaymentFailure',
}


export const URLs = {
    base: GetBaseURL(),
    Gettoken: '/token',
    RegisterAppNotificationToken: '/API/Users/RegisterBrowserNotificationToken',
    GetMyProfile: '/API/Users/GetMyProfile',
    GetMyNotifications: '/API/Users/GetMyNotifications',
    EditProfile: '/API/Users/EditProfile',
    ForgotPassword: '/API/Users/ForgotPassword',
    ChangePassword: '/API/Users/ChangePassword',
    VerifyOTP: '/VerifyEmail',
    ResendOTP: '/ResendVerificationEmail',
    GetESIMCountries: '/GetESIMCountries',
    GetESIMBundles: '/GetESIMBundles',
    RegisterESIMLife: '/RegisterESIMLife',
    ContactUs: '/ContactUs',
    GetNav: '/API/ACS/GetNav',
    OrderESIM: '/OrderESIM',
    GetESIMOrders: '/GetESIMOrders',
    ViewESIMOrderDetails: '/ViewESIMOrderDetails',
    PaymentInitiate: '/PaymentInitiate',



    //Google And FaceBook Keys
    googlekey: 'AIzaSyAii_9SU6ec6sCpwyaHIurMBvktQ8fv6JI',//google API key
    googleSiginClientID: '486407248016-f3549ujakk7jpsn560rl02fc80u4kjjo.apps.googleusercontent.com',
    Recaptchakey: '6LfS7iIqAAAAAGvnu0IPcUwZtcS-sibR4fNUEblA',//Recaptcha API key

    //STRIPE  PAYMENT INTEGRATION APIS
    GetStripeSecretKeys: 'https://api.stripe.com/v1/payment_intents',

    // SUPER PAYMENT INTEGRATION API
    SuperPaymentToken: GetSuperPaymentToken(),
    SuperPaymentAuth: GetSuperPaymentURL(),
    SuperPaymentRedirectURL: SuperPaymentRedirectURL,
    StripePaymentRedirectURL: StripePaymentRedirectURL,
    SubmitSuperPaymentDetails: 'CorporateSuperPaymentAPI/PaymentWithSuperPaymentSuccess',
    SuperpaymentFailure: 'CorporateSuperPaymentAPI/SuperPaymentFailure',
    SupercancelPayment: 'CorporateSuperPaymentAPI/CancelSuperPayment',

}