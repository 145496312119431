import React, { useEffect, useState } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { RiMenu3Fill } from 'react-icons/ri';
import { CiShoppingCart } from "react-icons/ci";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Text } from '../../components';
import { BaseColor, Images } from '../../config';
import './navbar.css';
import { useTranslation } from 'react-i18next';
import LanguageDropdown from './languageDropdown';
import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../config/appConstant';

const NavbarData = [
  { 'Name': 'Home', 'ID': 0, 'link': '/home' },
  { 'Name': 'Destinations', 'ID': 0, 'link': '/destination' },
  { 'Name': 'MyeSIM', 'ID': 0, 'link': '/myeSIM' },
  { 'Name': 'Get_Help', 'ID': 0, 'link': '/gethelp' },
  { 'Name': 'About_Us', 'ID': 0, 'link': '/aboutus' },
  { 'Name': 'Contact_Us', 'ID': 0, 'link': '/contactus' },
  // { 'Name': 'USD($)', 'ID': 0, 'link': '' }
]


const Navbar = () => {
  const navigate = useNavigate()

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState('');
  const [NavShow, setNavShow] = useState(false);
  const cartcount = useSelector((state) => state.cart);



  useEffect(() => {
    const splitlink = pathname.split("/");
    const activepath = splitlink[1];
    setActiveLink("/" + activepath)
    let ScreenName = capitalizeFirstLetter(window.location.pathname.split('/')[1])
    document.title = `eSIMLife | ${ScreenName}`;
  });

  return (
    <nav id='nav' className={NavShow ? 'nav-show' : ''} >
      <div className="nav-main">
        <div className="nav-logo">
          <Link to="/home" aria-label='home'><img src={Images.mainlogo} alt='Technolee' className='logo' /></Link>
        </div>
        <input type="radio" name="slider" id="menu-btn" readOnly />
        <input type="radio" name="slider" id="close-btn" checked readOnly />
        <ul className="nav-links">
          <label htmlFor="close-btn" className="btn close-btn"><AiOutlineClose /></label>
          {NavbarData.map((item,key) => {
            return (
              <li key={key}>
                {item.link == '' ? <Text bold>{t(item.Name)}</Text> :
                  <Link to={item.link} className={activeLink == item.link ? 'active-link' : ''} >{<Text primaryColor={activeLink == item.link ? true : false}>{t(item.Name)}</Text>}</Link>
                }
              </li>
            )
          })}
          <div className='cart-devider-div'>
            <div className='cart-devider'></div>
            {/* <img src={Images.cart} className='cart-icon' onClick={ () => navigate('/cart')}  /> */}
          </div>
          {/* <div style={{ width: '3%', position: 'relative' }}>
            <div className='cart-count-div'>
              <Text bold whiteColor>{cartcount}</Text>
            </div>
          </div> */}
          <div className='language-dropdown'>
            <LanguageDropdown />
          </div>
        </ul>

        <label htmlFor="menu-btn" className="btn menu-btn"><RiMenu3Fill color={BaseColor.text}/></label>
      </div>
    </nav>
  );
};

export default Navbar;