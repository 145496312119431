// src/components/ESIMCard.js

import React from 'react';
import './ESIMCard.css';
import { Button, Text } from '../../components';
import { Images } from '../../config';
import { useTranslation } from 'react-i18next';
import { downloadImage, formatCurrency, IsEmpty } from '../../config/appConstant';

const ESIMCard = ({ title, content, ViewDetailClick,listItems, buttonText, item, buttonLink }) => {
  const { t } = useTranslation();

  return (
    <div className="ESIMCard-mainContainer">
      <div className='ESIMCard-LeftContainer'>
        <div className="ESIMCard-title"><Text lineheightsmall bold primaryColor title5>{'ESIM :: ' + title}</Text></div>
        {/* <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'PriceTotal : ' + formatCurrency(item.PriceTotal)}</Text></div> */}
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'CountryName : ' + item.CountryName}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'BundleName : ' + item.BundleName}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'EmailAddress : ' + item.EmailAddress}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'OrderedDateTime : ' + item.OrderedDateTime}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'VATPercentage : ' + item.VATPercentage + '%'}</Text></div>
        <div className="ESIMCard-content"><Text lineheightsmall regular body2>{'BundleDescription : ' + item.BundleDescription}</Text></div>
        <div className="ESIMCard-content tag-flex" ><Text lineheightsmall regular body2>OrderStatus : </Text> <div className={item.OrderStatus == 'Failure' ? 'emsimcard-tag-failure' : 'emsimcard-tag-success'} ><Text lineheightsmall whiteColor regular body2> {item.OrderStatus}</Text></div></div>

      </div>
      {!IsEmpty(item.QRCode) && <div className='ESIMCard-rightContainer'>
        <img src={item.QRCode} alt='Technolee' className='ESIMCard-logo' />
        <div className='esimcard-button-flex'>
          <Button onClick={() => downloadImage(item.QRCode,('ESIM :: ' + title))} full title={t('Download QR')} />
          <Button onClick={ViewDetailClick} full title={t('View_Detail')} />
        </div>
      </div>}

    </div>
  );
};

export default ESIMCard;
