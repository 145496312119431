import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ProgressBar, Text } from '../../components';
import { downloadImage, IsEmpty } from '../../config/appConstant';
import './ESIMCardDetail.css';

const ESIMCardDetail = ({ title, content, ViewDetailClick, listItems, buttonText, item, buttonLink }) => {
  const { t } = useTranslation();

  return (
    <div className="eSIMCardDetail-mainContainer">
      <div className='eSIMCardDetail-LeftContainer'>
        <div className="eSIMCardDetail-title"><Text lineheightsmall bold primaryColor title5>{'ESIM :: ' + title}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{'BundleDescription : ' + item.BundleDescription}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{'ActivationCode : ' + item.ActivationCode}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{'FirstInstallationDateTime : ' + item.FirstInstallationDateTime}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{'SMDPAddress : ' + item.SMDPAddress}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{'CountryName : ' + item.CountryName}</Text></div>
        <div className="eSIMCardDetail-content"><Text lineheightsmall regular body2>{'IsBundleUsed : ' + item.IsBundleUsed}</Text></div>
        <div className="eSIMCardDetail-content tag-flex" ><Text lineheightsmall regular body2>{"ProfileStatus :"} </Text> <div className={item.OrderStatus == 'Installed' ? 'emsimcard-tag-failure' : 'emsimcard-tag-success'} ><Text lineheightsmall whiteColor regular body2> { }</Text></div></div>
        <div className='eSIMCardDetail-content'>
          <Text lineheightsmall bold body2 PurpleColor>{(item.BundleType == 'UL' ? 'Unlimited Lite: ' : item.BundleType == 'UP' ? 'Unlimited Plus: ' : '')}</Text>
          <Text lineheightsmall regular body2>{item.BundleTypeDescription}</Text>
        </div>
        <div className='eSIMCardDetail-progress'>
          <Text lineheightmedium regular body2>{t("Validity") + ":"} </Text>
          <ProgressBar ProgressValue={item.ProgressPercentage} />
        </div>
        <div className='eSIMCardDetail-progress'>
          <Text lineheightmedium regular body2>{t("Used") + ":"} </Text>
          <ProgressBar ProgressValue={item.UsedDaysPercentage} />
        </div>
      </div>
      {!IsEmpty(item.QRCode) &&
        <div className='eSIMCardDetail-rightContainer'>
          <img src={item.QRCode} alt='Technolee' className='eSIMCardDetail-logo' />
          <div className='eSIMCardDetail-button-flex'>
            <Button onClick={() => ViewDetailClick(item)} full title={t('Add_More_Bundle')} />
          </div>
          <div className='eSIMCardDetail-Qrbottomtext' >
            <Text bold> Only add a new bundle to this esim if it is still installed on your handset. You can check by confirming the ICCID of this esim is still showing in the about page of your device. if not Please purchase from the main home page of the app.</Text>
          </div>
        </div>}

    </div>
  );
};

export default ESIMCardDetail;
