import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import './App.css';
import { BaseImages, FontFamily, useTheme } from './source/config';
import { AboutUs, Cart, ContactUs, Destination, ESIMDetail, ESIMOrders, GetHelp, Home, OTP, PaymentFailure, PaymentSuccess, ScreenLayout, SignIn, SignUp,TermsAndCondition } from "./source/config/PagePathList";
import { setDirection } from './source/redux/store';


function App() {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const dir = useSelector((state) => state.dir);
  const IsLogin = useSelector((state) => state.il);
  const BaseColor1 = useTheme().theme.colors



  useEffect(() => {
    // Update CSS variables with JavaScript  // For Global Colors And Images Call From Api as well as appconfig file
    Object.entries(BaseImages).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
    Object.entries(FontFamily).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
    Object.entries(BaseColor1).forEach(([key, value]) => {
      document.documentElement.style.setProperty(`--${key}`, value);
    });
  }, []);

  const getDirection = (lang) => {
    dispatch(setDirection(['ar'].includes(lang) ? 'rtl' : 'ltr'));
    return ['ar'].includes(lang) ? 'rtl' : 'ltr';
  };


  useEffect(() => {
    document.documentElement.dir = getDirection(i18n.language);
  }, [i18n.language]);



  if (!IsLogin) {
    return (
      <div>
        <ToastContainer style={{ zIndex: 5000000 }} position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} rtl={dir == 'rtl'} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
        <Routes>
          <Route path="/login" element={<SignIn />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/otp" element={<OTP />} />
          <Route path="/termsandcondition" element={<TermsAndCondition />} />
        </Routes>
      </div>
    );
  }
  else {
    return (
      <>
        <ToastContainer style={{ zIndex: 5000000 }} position="bottom-right" autoClose={3000} hideProgressBar={false} newestOnTop={false} rtl={dir == 'rtl'} pauseOnFocusLoss draggable pauseOnHover theme="dark" />
        <ScreenLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/destination" element={<Destination />} />
            <Route path="/gethelp" element={<GetHelp />} />
            <Route path="/myeSIM" element={<ESIMOrders />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/contactus" element={<ContactUs />} />
            <Route path="/login" element={<SignIn />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/PaymentSuccess" element={<PaymentSuccess />} />
            <Route path="/PaymentFailure" element={<PaymentFailure />} />
            <Route path="/eSIMDetail" element={<ESIMDetail />} />
            <Route path="/termsandcondition" element={<TermsAndCondition />} />
          </Routes>
        </ScreenLayout>
      </>
    )
  }
}

export default App;


