import { IconButton, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { IoClose } from "react-icons/io5";

import CommonButton from "../Button/Button";
import './commonmodal.css';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../config';

function CommonModal({ visible, Title, isOuterClick, ActionText, onClose, maxWidth, IsLoading, onSave, IsScroll, children, IsDeletePopup, OrderNO, IsDeleteMassage, SaveButtonName, CancelButtonName, showCloseIcon = true }) {
    const { t } = useTranslation();
    const BaseColor= useTheme().theme.colors

    const handleClose = () => {
        onClose(false)
    };

    // let lastWord = Title.substring(Title.lastIndexOf(" ") + 1);
    // 'xs'    | 'sm'    | 'md'    | 'lg'    | 'xl'
    return (
        <div>
            <Dialog
                open={visible}
                maxWidth={maxWidth}
                onClose={isOuterClick == undefined ? handleClose : isOuterClick}
                scroll={IsScroll != undefined ? 'body' : 'paper'}
                aria-labelledby="scroll-dialog-title">
                <div className='main-modal-wrapper'>
                    {showCloseIcon && <IconButton  onClick={handleClose} className="close-icon" style={{ position: 'absolute', right: '10px', top: '5px' }}><IoClose size={25} color={BaseColor.text} /></IconButton>}
                    {Title&&<div style={{ display: 'flex', justifyContent: 'space-between', }}>
                        <DialogTitle style={{ marginBottom: '0px' }} className='commonmodel-title' id="scroll-dialog-title">{IsDeleteMassage ? t(`${ActionText}`) : t(`${Title}`)}</DialogTitle>
                        {OrderNO != "" && OrderNO != undefined && OrderNO != null && <DialogTitle className='commonmodel-title' style={{ marginBottom: '0px' }} > {OrderNO}</DialogTitle>}
                    </div>}

                    <DialogContent style={{ overflow: 'hidden', }} >
                        <DialogContentText>
                            {IsDeletePopup && <Typography color='textPrimary' style={{ fontSize: 20, fontWeight: 'bold' }}>{IsDeleteMassage ? IsDeleteMassage : `Are you sure you want to ${Title}?`}</Typography>}
                            {children}
                        </DialogContentText>
                    </DialogContent>
                    {IsDeletePopup &&
                        <DialogActions>
                            <CommonButton loading={IsLoading} round onClick={onSave} color="primary" title={SaveButtonName ? SaveButtonName : ActionText}></CommonButton>
                            <CommonButton disabled={IsLoading ? true : false} round type={3} onClick={handleClose} title={CancelButtonName ? CancelButtonName : "Close"} color='secondary' ></CommonButton>
                        </DialogActions>}

                </div>
            </Dialog>

        </div>
    );

}

export default CommonModal;
