import React from 'react';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from "prop-types";
import ButtonStyle from './Buttontyle';
import {  useTheme } from '../../config';

const ButtonBase = props => {
  const classes = ButtonStyle();
  let BaseColor = useTheme().theme.colors

  const { title, onClick, type, ButtonType, full, endIcon, small,capsule, extraSmall, Medium, Large, customClass, round, startIcon, disabled, variant, loading } = props;

  return (
    <Button
      startIcon={startIcon}
      endIcon={endIcon}
      className={`${classes.default} ${customClass} ${round && classes.round} ${small && classes.small} ${extraSmall && classes.extraSmall} ${Medium && classes.Medium} ${capsule && classes.capsule} ${Large && classes.Large} ${variant === 'contained' ? classes.textDefaultContained : classes.textDefaultOutline}`}
      fullWidth={full}
      variant={variant}
      type={ButtonType}
      onClick={onClick}
      disabled={loading ? true : disabled}
      style={{ backgroundColor: disabled ? BaseColor.darkGray : variant === 'outlined' ? '' : type === 1 ? BaseColor.whiteColor : type === 2 ? BaseColor.mainColor : type === 3 ? BaseColor.darkblueButtonColor : type === 4 ? BaseColor.whiteColor : type === 5 ? BaseColor.grayColor : BaseColor.ThirdButtonColor ,color: type === 2 || type === 3 ? BaseColor.whiteColor : type === 4 ? BaseColor.DarkGrayTextColor : '' }}
      {...props}>
      {loading && <CircularProgress color={BaseColor.mainButtonColor} size={15} style={{ marginRight: '5px' }} />}
      {title}
    </Button>
  );
};
ButtonBase.propTypes = {
  customClass: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  startIcon: PropTypes.node,
  variant: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  full: PropTypes.bool,
  round: PropTypes.bool,
  loading: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  Medium: PropTypes.bool,
  Large: PropTypes.bool,
  extraSmall: PropTypes.bool,
};

ButtonBase.defaultProps = {
  customClass: {},
  startIcon: null,
  variant: 'contained',
  full: false,
  round: false,
  type: 1,
  small: false,
  disabled: false,
  title: '',
  Medium: false,
  Large: false,
  extraSmall: false,
  loading: false,
};
export default ButtonBase;
