import React from 'react';
import './DestinationPlaceholder.css';
import { Button, Text } from '../../components';

const DestinationPlaceholder = () => (
    <section className="destinationplaceholder-main">
        <div className='destinationplaceholder-header'>
            <div className='destinationplaceholder-headertext' ><Text lineheightreguler whiteColor bold heading >Lorem ipsum is <br />  placeholder</Text></div>
            <div ><Button round capsule title={'Lorem ipsum'} /></div>
        </div>
        <div className="destinationp-content">
            <div className="destinationp-card">
                <div className="image-placeholder-x"></div>
                <div className='card-para'>
                    <Text lineheightsmall blackColor medium body1 >Lorem ipsum dolor sit amet consectetur. Sit malesuada maecenas lectus malesuada dui auctor natoque dui. Morbi congue sociis</Text>
                </div>
            </div>
            <div className="destinationp-card card-align">
                <div className="image-placeholder-x"></div>
                <div className='card-para'>
                    <Text lineheightsmall blackColor medium body1 >Lorem ipsum dolor sit amet consectetur. Sit malesuada maecenas lectus malesuada dui auctor natoque dui. Morbi congue sociis</Text>
                </div>
            </div>
        </div>
    </section>
);

export default DestinationPlaceholder;
